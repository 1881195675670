import React from "react"
import { Link } from "gatsby"
import "../../assets/css/style.css"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import logo3 from "../../assets/images/logos/logo3.png"
import footerMap from "../../assets/images/footer-map.png"

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer-area bg-color">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-footer-widget">
              <a href="/" className="logo">
                <img
                  src={logo3}
                  alt="logo"
                  className="footer-logo ml-3"
                  style={{ height: "120px" }}
                />
              </a>
              <p>Visit our Social Channels</p>

              <ul className="social-link">
                <li>
                  {/* <Link
                    to="#"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  > */}
                  <a
                    className="d-block"
                    href="https://www.facebook.com/Mexxar-108597611275576/"
                  >
                    <i className="bx bxl-facebook"></i>
                  </a>

                  {/* </Link> */}
                </li>
                {/* <li>
                  <Link
                    to="#"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-twitter"></i>
                  </Link>
                </li> */}
                <li>
                  {/* <Link
                    to="#"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  > */}
                  <a
                    className="d-block"
                    href="https://www.instagram.com/mexxar_software_solutions/"
                  >
                    <i className="bx bxl-instagram"></i>
                  </a>
                  {/* </Link> */}
                </li>
                <li>
                  {/* <Link
                    to="#"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  > */}
                  <a
                    className="d-block"
                    href="https://www.linkedin.com/company/mexxar/"
                  >
                    <i className="bx bxl-linkedin"></i>
                  </a>
                  {/* <i className="bx bxl-linkedin"></i> */}
                  {/* </Link> */}
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget pl-5">
              <h3>Explore</h3>

              <ul className="footer-links-list">
                <li>
                  <AnchorLink
                    to="/#main-banner-id"
                    title="Your digital future REDEFINED"
                    activeClassName="active"
                    // className="nav-link nav-link-custom"
                  >
                    <span>Home</span>
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink
                    to="/#weAre"
                    title="Digitalized your world with us"
                    activeClassName="active"
                    // className="nav-link"
                  >
                    <span>We are</span>
                  </AnchorLink>{" "}
                </li>
                <li>
                  <AnchorLink
                    to="/#ourServices"
                    title="Services We Can Help You With"
                    activeClassName="active"
                    // className="nav-link"
                  >
                    <span>Our Services</span>
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink
                    to="/#mexxarCRM"
                    title="MEXXAR RECRUITMENT VERSION 1.0"
                    activeClassName="active"
                    // className="nav-link"
                  >
                    <span>Mexxar CRM</span>
                  </AnchorLink>{" "}
                </li>
                {/* <li>
                  <Link to="/contact">Careers</Link>
                </li> */}
              </ul>
            </div>
          </div>

          {/* <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Resources</h3>
                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/team">
                                        Our Scientists
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/services">
                                        Our Services
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/testimonials">
                                        Testimonials
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/services">
                                        SaaS Solutions
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/case-studies">
                                        Case Studies
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div> */}

          <div className="col-lg-4 col-sm-6 ml-5">
            <div className="single-footer-widget">
              <h3>Contact</h3>

              <ul className="footer-contact-info">
                {/* <li>
                  <i className="bx bx-map"></i>
                  3D, Sangamitta Mawatha, Kandy <br /> Sri Lanka
                </li> */}
                <li>
                  <i className="bx bx-map"></i>
                  Headquarters : United Kingdom <br /> R&D Center : Sri Lanka{" "}
                  <br />
                </li>
                <li>
                  <i className="bx bx-phone-call"></i>
                  <a href="tel:+44 20 8191 8747">+44 20 8191 8747</a>
                </li>
                <li>
                  <i className="bx bx-envelope"></i>
                  <a href="mailto:info@mexxar.com">info@mexxar.com</a>
                </li>
                {/* <li>
                                    <i className='bx bxs-inbox'></i>
                                    <a href="tel:+557854578964">+55 785 4578964</a>
                                </li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-bottom-area">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <p>
                Copyright @ {currentYear} <strong>Mexxar (Pvt) Ltd. </strong>{" "}
                All rights reserved <a target="_blank" rel="noreferrer"></a>
              </p>
            </div>

            <div className="col-lg-6 col-md-6">
              <ul>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link
                  // to="/terms-of-service"
                  >
                    Terms & Conditions
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* 
      <div className="footer-map">
        <img src={footerMap} alt="footer-logo" />
      </div> */}
    </footer>
  )
}

export default Footer
