import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>Mexxar</title>
                <meta name="description" content="Mexxar is a robust consultancy and professional software development and IT services provider. At Mexxar, we specialize in developing revolutionized and innovative software-based products for different companies and applications in diversified industries." />
                <meta name="og:title" property="og:title" content="Mexxar"></meta>
                <meta name="twitter:card" content="Mexxar"></meta>
                <link rel="canonical" href="https://mexxar.com/"></link>
                <meta property="og:image" content="https://res.cloudinary.com/dev-empty/image/upload/v1593069801/explore-learning.jpg" />
            </Helmet>
        </div>
    )
}

export default SEO
